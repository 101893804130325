<template>
<div>
  <v-card
    color="grey lighten-4"
    flat
    tile
  >
    <v-app-bar
        color="#50d8af"
        class="light-text"
        light
        dense>
      <v-app-bar-nav-icon 
        class="light-text"
        @click="drawer=!drawer"></v-app-bar-nav-icon>

      <v-toolbar-title class="light-text">Mihandzu Portal</v-toolbar-title>

      <v-spacer></v-spacer>

    </v-app-bar>
  </v-card>

 <v-navigation-drawer
      v-model="drawer"
      bottom
      absolute
      temporary
    >
      <v-list
        nav
        dense
      >
        <v-list-item-group
          v-model="group"
          active-class="deep-purple--text text--accent-4"
        >
        <router-link to='/volunteers'>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Volunteers</v-list-item-title>
          </v-list-item>
        </router-link>

        <router-link to='/messages'>
          <v-list-item>
            <v-list-item-icon>
              <v-icon>mdi-email</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Messages</v-list-item-title>
          </v-list-item>
        </router-link>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>

    <v-container>
        <br/><br/>
        <v-row>
            <h1>Volunteers</h1>
        </v-row>
        <v-row>
            <v-divider></v-divider>
        </v-row>
        <v-row>

            <v-expansion-panels popout>
                <v-expansion-panel
                    v-for="item in getVolunteers()"
                    :key="item.id"
                >
                    <template v-if="!item.message_text">
                        <v-expansion-panel-header>{{item.name || item.fullname}}</v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-row>
                                    <span>email:</span>{{item.email}}
                            </v-row>
                            <v-row>
                                    <span>cell:</span>{{item.cellphone}}
                            </v-row>
                            <v-row v-if="item.age">
                                <p>
                                    {{item.age}} years old
                                </p>
                            </v-row>
                            <v-row v-if="item.tutoring_experience">
                                <p>
                                    Volunteer has tutored before
                                </p>
                            </v-row>
                            <v-row v-if="item.highest_level_tutored">
                                <p>
                                    Has experience tutoring : {{item.highest_level_tutored}}
                                </p>
                            </v-row>
                            <v-row v-if="item.transport_available">
                                <p>
                                    Volunteer has own transport
                                </p>
                            </v-row>
                            <v-row v-if="item.comfortable_talking">
                                <p>
                                    Is comfortable talking infront of a class
                                </p>
                            </v-row>
                        </v-expansion-panel-content>
                    </template>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-row>
    </v-container>
</div>
</template>

<script>
import network_config from '../../utils/config'

export default{
    name: 'Volunteers',
    data: () => ({
          volunteers: [],
          drawer: false,
          group: null,
        }),
    methods:{
        tutors(){
            return this.volunteers
        },
        getVolunteers(){
            if (this.volunteers === undefined || this.volunteers.length == 0) {
                this.$axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
                this.$axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
                this.$axios.get(network_config.network.VOLUNTEERS_ENPOINT).then((res)=>{
                    this.volunteers = res.data.volunteers
                })
                .catch((err)=>{
                    console.log(err)
                })
            }
            return this.volunteers;
        }
    },
    watch: {
      group () {
        this.drawer = false
      },
    },
    beforeCreate(){
      if(!this.$cookies.get('user')){
        this.$router.push('ngena')
      }
    }
}
</script>

<style scoped>
    .light-text{
        color: #fff;
    }
</style>
