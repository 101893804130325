import Vue from 'vue'
import Router from 'vue-router'

import articles from '@/components/Articles'
import home from '@/components/Home.vue'
import join from '@/components/Join.vue'
import help from '@/components/Help.vue'

import ngena from '@/components/admin/Ngena.vue'
import messages from '@/components/admin/Messages.vue'
import volunteers from '@/components/admin/Volunteers.vue'

Vue.use(Router)

export default new Router({
    mode: "history",
    base: process.env.BASE_URL,
    routes:[
    {
      path: '/',
      name: 'home',
      component: home,
      alias: '/home'
    },
    {
      path: '/articles/:artId',
      name: 'articles',
      component: articles,
    },
    {
      path: '/join',
      name: 'join',
      component: join,
    },
    {
      path: '/help',
      name: 'help',
      component: help,
    },
    {
      path: '/ngena',
      name: 'ngena',
      component: ngena,
    },
    {
      path: '/volunteers',
      name: 'volunteers',
      component: volunteers,
    },
    {
      path: '/messages',
      name: 'messages',
      component: messages,
    }
  ]
})