<template>
    <v-container>
        <v-alert
            v-model="login_error"
            dismissible
            outlined
            prominent
            type="error"
            >
            Eish. Incorrect details entered
        </v-alert>
        <div class="container bottom-bum">
            <img class="scrollto" src="../../assets/logo.png" alt="" title="" />
        </div>
        <v-row>
            <v-col cols="12">
                <v-card 
                 elevation="8"
                 class="white-text"
                 color="#50d8af"
                 dark>
                    <v-card-title
                    class="text-h6 font-weight-light">
                        <h1 class="white-text">Login</h1>
                    </v-card-title>
                    <v-card-text>
                        <v-form>
                        <v-text-field 
                            v-model="login_details.username"
                            label="Username"
                            prepend-icon="mdi-account-circle"
                        />
                        <v-text-field 
                            v-model="login_details.pass"
                            type="Password"
                            label="Password"
                            prepend-icon="mdi-lock"
                            required
                        />
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <template 
                            class="text-center">
                            <v-btn 
                                class="ma-2"
                                outlined
                                block
                                max-width="30"
                                @click="loginAdmin()"
                                >Login</v-btn>
                        </template>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default{
        data: () => ({
            login_details: {},
            login_error: false,
        }),
        methods:{
            loginAdmin(){
                console.log(this.login_details)
                if(this.login_details.pass == "access the fruit of knowledge" && this.login_details.username.toLowerCase() == "mihandzu_admin"){
                    var user = { login_status:1, name:'admin'}
                    this.$cookies.set('user',user)
                    this.$router.push('volunteers')
                }
                else{
                    this.login_error = true
                }
            }
        }
    }
</script>

<style>
.white-text{
    color: #fff;
}

.bottom-bum{
    margin-bottom: 2em;
}
</style>