<template>
  <div class="home">
  <!--
    Top Bar
  -->
  <section id="topbar" class="d-none d-lg-block">
    <div class="container clearfix">
      <div class="contact-info float-left">
        <i class="fa fa-envelope-o"></i> <a href="mailto:contact@example.com">info@mihandzulearn.org</a>
        <i class="fa fa-phone"></i> <a href="tel:+27728165302">+27 72 816 5302</a>
      </div>
      <div class="social-links float-right">
        <a href="https://twitter.com/mihandzulearn" target="_blank" class="twitter"><i class="fa fa-twitter"></i></a>
        <a href="https://www.facebook.com/Mihandzulearn" target="_blank" class="facebook"><i class="fa fa-facebook"></i></a>
        <a href="https://www.instagram.com/mihandzu/" target="_blank" class="instagram"><i class="fa fa-instagram"></i></a>
        <a href="https://za.linkedin.com/company/mihandzu-learning" target="_blank" class="linkedin"><i class="fa fa-linkedin"></i></a>
      </div>
    </div>
  </section>
  <!--
    Header
  -->
  <header id="header" v-sticky="{zIndex:50, stickyTop:0}">
    <div class="container">

      <div id="logo">
        <a href="#body"><img class="scrollto" src="../assets/logo.png" alt="" title="" /></a>
      </div>

      <nav id="nav-menu-container">
        <ul class="nav-menu">
          <li class="menu-active"><a href="#intro">Home</a></li>
          <li><a href="/Home#about">About Us</a></li>
          <li><a href="#portfolio">Articles</a></li>
          <li><a href="#team">The Team</a></li>
          <li><a href='#contact'>Contact</a></li>
          <li><router-link to='/join'>Become a Tutor</router-link></li>
        </ul>
      </nav>
    </div>
  </header>
  <!--
    Intro Section
  -->
  <section id="intro">
    <div class="intro-content">
      <h2>Prosperity Through Education</h2>
    </div>
    <div id="intro-carousel" class="owl-carousel" >
    </div>
  </section>

  <!--
    Main Section
  -->
  <main id="main">

    <!--
      About Section
    -->
      <section id="about" class="wow fadeInUp">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 about-img">
              <img src="../assets/images/welcome_image.jpg" alt="">
            </div>

            <div class="col-lg-6 content">
              <h2>Who are we?</h2>
              <h3>
                Mihandzu is a non profit organizagtion that aims to improve the academic performance and understanding of 
                learners, with a primary focus on subjects and concepts critical in developing individuals who can navigate and prosper 
                in the new digital economy.
                <br/>
                Our areas of focus are:
              </h3>
              <ul>
                <li><i class="ion-android-checkmark-circle"></i> <b>Mathematics</b></li>
                <li><i class="ion-android-checkmark-circle"></i> <b>Information Technology</b></li>
                <li><i class="ion-android-checkmark-circle"></i> <b>Carreer Guidance</b></li>
              </ul>

            </div>
          </div>

        </div>
      </section>
    <!--
      Services Section
    -->
    <section id="services">
      <div class="container">
        <div class="section-header">
          <h2>What do we do?</h2>
          <p>
            Established in 2015, Mihandzu has weekly sessions designed to equip learners with the foundational knowledge
            and skill sets required to approach each subject. This combined with our career guidance support creates an 
            an environment that encourages understanding.
          </p>
          <br/>

        </div>

        <div class="row">

          <div class="col-lg-6">
            <div class="box wow fadeInLeft" data-wow-delay="0.2s">
              <div class="icon"><img src="../assets/images/icons/lessons.jpg" alt=""></div>
              <h4 class="title">Weekly Lessons</h4>
              <p class="description">
                Tutorial classes are conducted every Saturday during the school calendar to increase the contact time that each learner is exposed to the subject.
              </p>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="box wow fadeInRight">
              <div class="icon"><img src="../assets/images/icons/tutors.jpg" alt=""></div>
              <h4 class="title">Young Tutors</h4>
              <p class="description">
                Mihandzu tutors are mainly university students which means the age gap between the tutors and learners is small which 
                makes communication easier.
              </p>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="box wow fadeInLeft" data-wow-delay="0.2s">
              <div class="icon"><img src="../assets/images/icons/small_classes.jpg" alt=""></div>
              <h4 class="title">Small Classes</h4>
              <p class="description">
                Smaller classes encourage learners to ask questions and allows the tutors to answer in detail.
              </p>
            </div>
          </div>

          <div class="col-lg-6">
            <div class="box wow fadeInRight">
              <div class="icon"><img src="../assets/images/icons/meals.jpg" alt=""></div>
              <h4 class="title">Meals</h4>
              <p class="description">
                Meals are provided to the learners every session to ensure that their concentration is not limited.
              </p>
            </div>
          </div>

        </div>

      </div>
    </section><!-- #services -->

    <!--
      Impact Section
    -->
    <section id="impact" class="wow fadeInUp">
      <div class="container">
        <div class="section-header">
          <h2>Impact</h2>
            <div class="row">
              <div class="col-lg-6">
                <div class="box wow fadeInRight">
                  <div class="icon"><img src="../assets/images/icons/increase_students.jpg"></div>
                  <h4><span class='highlight'>60%</span> increase in students taking Mathematics Core after our arrival</h4>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="box wow fadeInLeft" data-wow-delay="0.2s">
                  <div class="icon"><img src="../assets/images/icons/many_learners.jpg" alt=""></div>
                  <h4>More than <span class='highlight'>3000 Learners</span> reached and growing every day</h4>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="box wow fadeInRight">
                  <div class="icon"><img src="../assets/images/icons/meals2.jpg" alt=""></div>
                  <h4>Over <span class='highlight'>10000 Meals</span> distributed</h4>
                </div>
              </div>

              <div class="col-lg-6">
                <div class="box wow fadeInRight">
                  <div class="icon"><img src="../assets/images/icons/clock.jpg" alt=""></div>
                  <h4>More than <span class='highlight'>7200 Hours</span> dontated by our amazing network of tutors</h4>
                </div>
              </div>
           </div>
        </div>
      </div>
    </section>

    <!--
      Sponsor Section
    -->
    <section id="sponsors" class="wow fadeInUp">
      <div class="container">
        <div class="section-header">
          <h2>Our Sponsors</h2>
          <p>Mihandzu has had the privilage of working with a wide spectrum of organizations that believe in our vision and mission statement.</p>
        </div>
        <carousel :autoplay="true" :nav="false" :dots="false" :responsive="{0:{items:3},600:{items:4}}">
          <img src="../assets/images/sponsor1.jpg" alt="The Learning Trust">
          <img src="../assets/images/sponsor2.jpg" alt="Sasol">
          <img src="../assets/images/sponsor3.jpg" alt="Nedbank">
          <img src="../assets/images/sponsor6.jpg" alt="Wits University">
          <img src="../assets/images/sponsor5.jpg" alt="University of Johannesburg">
          <img src="../assets/images/sponsor4.jpg" alt="GEMS">
        </carousel>
        <br/><br/>
      </div>
    </section>

    <!--
      Testimonials Section
    -->
    <section id="testimonials" class="wow fadeInUp">
      <div class="container">
        <div class="section-header">
          <h2>Testimonials</h2>
        </div>
        <carousel :autoplay="true" :nav="false" :dots="true" :responsive="{0:{items:1},600:{items:2}}">
            <div class="testimonial-item">
              <p>
                <img src="../assets/images/icons/quote-sign-left.png" class="quote-sign-left" alt="">
                I used to think Maths was very difficult and that it was not for me, but after I  took the time to do the work I see that it is about practice and self belief.
                <img src="../assets/images/icons/quote-sign-right.png" class="quote-sign-right" alt="">
              </p>
              <h3>Khosi Mabobotja</h3>
              <h4>Gr.9 Maths Student</h4>
            </div>

            <div class="testimonial-item">
              <p>
                <img src="../assets/images/icons/quote-sign-left.png" class="quote-sign-left" alt="">
                During the lockdown the work they sent us gave me something to look forward to everyday. It made going back to school not so scary because we already did the work.
                <img src="../assets/images/icons/quote-sign-right.png" class="quote-sign-right" alt="">
              </p>
              <h3>Mahlatse Makgotlo</h3>
              <h4>Gr.9 Maths Student</h4>
            </div>

            <div class="testimonial-item">
              <p>
                <img src="../assets/images/icons/quote-sign-left.png" class="quote-sign-left" alt="">
                I hated Maths because I was not good at it but then I discovered Mihandzu and Maths started to seem easy. I appreciate your help and the patience of Mihandzu's tutors.
                <img src="../assets/images/icons/quote-sign-right.png" class="quote-sign-right" alt="">
              </p>
              <h3>Kunashe Muchunje</h3>
              <h4>Gr.10 Maths Student</h4>
            </div>

            <div class="testimonial-item">
              <p>
                <img src="../assets/images/icons/quote-sign-left.png" class="quote-sign-left" alt="">
                From the basics to the most difficult parts of Mathematics I understood things better. The online sessions were also very helpful.
                <img src="../assets/images/icons/quote-sign-right.png" class="quote-sign-right" alt="">
              </p>
              <h3>Kwena Matekga</h3>
              <h4>Gr.10 Maths Student</h4>
            </div>

            <div class="testimonial-item">
              <p>
                <img src="../assets/images/icons/quote-sign-left.png" class="quote-sign-left" alt="">                
                Mihandzu's sessions were interesting and helpful too much. I really enjoyed it and I hope we will have them again this year.
                <img src="../assets/images/icons/quote-sign-right.png" class="quote-sign-right" alt="">
              </p>
              <h3>Steve Chauke</h3>
              <h4>Gr.9 Maths Student</h4>
            </div>

        </carousel>

      </div>
    </section>

    <!--
      Article Section
    -->
    <section id="portfolio" class="wow fadeInUp">
      <div class="container">
        <div class="section-header">
          <h2>Articles</h2>
          <p>Our articles are written by memebers of our team sharing their thoughts and some of the exciting things we have done</p>
        </div>
      </div>
      <div class="container-fluid">
              <div class="row no-gutters">

                <div class="col-lg-3 col-md-4" v-for="(data, index) in articles" :key="index">
                  <div @click="goToArticle(data.articleId)" class="portfolio-item wow fadeInUp">
                      <img :src="data.image" alt="">
                      <div class="portfolio-overlay">
                        <div class="portfolio-info"><h2 class="wow fadeInUp">{{data.articleTitle}}</h2></div>
                      </div>
                  </div>
                </div>
              </div>
      </div>
    </section>
    <!--
      Download
    -->
    <section id="download-profile" class="wow fadeInUp download">
      <div class="container">
        <div class="row">
          <div class="col-lg-9 text-center text-lg-left">
            <h3 class="cta-title">Download Our Profile</h3>
            <p class="cta-text"> 
              For more details on what we do and how we do it, download our profile to get an indepth understanding of Mihandzu
            </p>
          </div>
          <div class="col-lg-3 cta-btn-container text-center">
            <a download="mihandzu_profile" href='/mihandzu_profile.pdf' class="cta-btn align-middle">Download</a>
          </div>
        </div>

      </div>
    </section>

    <!--
      Our Team
    -->
    <section id="team" class="wow fadeInUp">
      <div class="container">
        <div class="section-header">
          <h2>Management Team</h2>
        </div>
        <div class="row">
          <div class="col-lg-3 col-md-6">
            <div class="member">
              <div class="pic"><img src="../assets/images/Khanya.png" alt=""></div>
              <div class="details">
                <h4>Nokukhanya Memela CA(SA)</h4>
                <span>Founder, Chairperson</span>
                <p>A chartered accountant who graduated from the university of Witswatersrand with a degree and post graduate qualification in accounting science. She currently works in the financial sector.</p>
                <div class="social">
                  <a href="https://twitter.com/khanzzz_m" target="_blank"><i class="fa fa-twitter"></i></a>
                  <a href="https://www.facebook.com/khanya.memela" target="_blank"><i class="fa fa-facebook"></i></a>
                  <a href="https://www.instagram.com/khanya_memela" target="_blank"><i class="fa fa-instagram"></i></a>
                  <a href="https://za.linkedin.com/in/khanya-memela-b2433697" target="_blank"><i class="fa fa-linkedin"></i></a>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-3 col-md-6">
            <div class="member">
              <div class="pic"><img src="../assets/images/Khanyi.png" alt=""></div>
              <div class="details">
                <h4>Khanyisa Dube</h4>
                <span>Mathematics Director</span>
                <p>Khanyisa is a senior manager in the model risk audit function at Absa. She graduated from the University of Capte Town with a degree in Statistics and an Msc in Mathematical Statistics from the University of Witswatersrand</p>
                <div class="social">
                  <a href="https://za.linkedin.com/in/pumza-kanyisa-dube-88877a98" target="_blank"><i class="fa fa-linkedin"></i></a>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6">
            <div class="member">
              <div class="pic"><img src="../assets/images/Thokozani.png" alt=""></div>
              <div class="details">
                <h4>Thokozani Memela</h4>
                <span>Information Technology Director</span>
                <p>A software engineer working for a local tech company who graduated from the University of Johannesburg with a Bsc in computer science.</p>
                <div class="social">
                  <a href="https://twitter.com/thokzmemela" target="_blank"><i class="fa fa-twitter"></i></a>
                  <a href="https://www.instagram.com/thokozani_memela/" target="_blank"><i class="fa fa-instagram"></i></a>
                  <a href="https://za.linkedin.com/in/thokozani-memela-86834011b" target="_blank"><i class="fa fa-linkedin"></i></a>
                </div>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6">
            <div class="member">
              <div class="pic"><img src="../assets/images/Phodiso.jpg" alt=""></div>
              <div class="details">
                <h4>Phodiso Seleka</h4>
                <span>Mathematics Vice Programme Director</span>
                <p>Phodiso is a senior quantitative analyst at a financial institution. He graduated from the University of Witswatersrand with an honours degree in Pure Mathematics.</p>
                <div class="social">
                  <a href="https://za.linkedin.com/in/phodiso-seleka-ab06801a7" target="_blank"><i class="fa fa-linkedin"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </section>

    <!--
      Contact Section
    -->
    <section id="contact" class="wow fadeInUp">

        <div class="section-header">
          <h2>Get in Touch</h2>
        </div>

      <div class="container">
        <div class="form">
          <div id="msgError" v-show="display_err" class="alert alert-danger alert-dismissible">
            <strong>Error:</strong> <span id="txtErr">Something went wrong. Please try again later.</span>
          </div>
          <div id="sendmessage">Your message has been sent. Thank you!</div>
          <div v-show="display_success" class="card text-white bg-success mb-3" style="max-width: 25rem;">
                <div class="card-header">Thank You</div>
                <div class="card-body">
                    <h5 class="card-title">Your message has been sent</h5>
                </div>
            </div>
          <div id="errormessage"></div>
          <form action="" method="post" role="form" class="contactForm">
            <div class="form-row">
              <div class="form-group col-md-6">
                <input type="text" v-model="new_message['fullname']" name="name" class="form-control" id="name" placeholder="Your Name" data-rule="minlen:4" data-msg="Please enter at least 4 chars" />
                <div class="validation"></div>
              </div>
              <div class="form-group col-md-6">
                <input type="email" v-model="new_message['email']" class="form-control" name="email" id="email" placeholder="Your Email" data-rule="email" data-msg="Please enter a valid email" />
                <div class="validation"></div>
              </div>
            </div>
            <div class="form-group">
              <input type="text" v-model="new_message['subject']" class="form-control" name="subject" id="subject" placeholder="Subject" data-rule="minlen:4" data-msg="Please enter at least 8 chars of subject" />
              <div class="validation"></div>
            </div>
            <div class="form-group">
              <textarea v-model="new_message['message_text']" class="form-control" name="message" rows="5" data-rule="required" data-msg="Please write something for us" placeholder="Message"></textarea>
              <div class="validation"></div>
            </div>
            <div class="text-center"><a class='btn-cool' v-on:click="contact">Send Message</a></div>
          </form>
        </div>

      </div>
       <div class="container">
        <div class="row contact-info">

          <div class="col-md-6">
            <div class="contact-phone">
              <i class="ion-ios-telephone-outline"></i>
              <h3>Phone Number</h3>
              <p><a href="tel:+27728165302">+27 72 816 5302</a></p>
            </div>
          </div>

          <div class="col-md-6">
            <div class="contact-email">
              <i class="ion-ios-email-outline"></i>
              <h3>Email</h3>
              <p><a href="mailto:info@mihandzulearn.org">info@mihandzulearn.org</a></p>
            </div>
          </div>

        </div>
      </div>
    </section>


  <a @click="scrollTop" href="#" class="back-to-top"><i class="fa fa-chevron-up"></i></a>

  <!--
    Footer
  -->
  <footer id="footer">
    <div class="container">
      <div class="copyright">
        &copy; Mihandzu Learning     <strong>2016/404953/08</strong>
      </div>
    </div>
  </footer>
  </main>
  </div>
</template>

<script>
import carousel from 'vue-owl-carousel';
import VueSticky from 'vue-sticky';
import $ from 'jquery';
import axios from 'axios';
import network_config from '../utils/config'

export default {
  name: 'Home',
  props: {
    msg: String
  },
  components: {
    carousel,
  },
  directives:{
    'sticky': VueSticky,
  },
  methods: {
    scrollTop: function () {
      this.intervalId = setInterval(() => {
        if (window.pageYOffset === 0) {
          clearInterval(this.intervalId)
        }
        window.scroll(0, window.pageYOffset - 50)
      }, 20)
    },
    scrollListener: function () {
      this.visible = window.scrollY > 150
    },
    goToArticle(artId){
      this.$router.push({name: 'articles', params: {artId:artId}})
    },
    contact: function(event){
      if(event){
        console.log(this.new_message);
        axios.post(
          network_config.network.VOLUNTEER_ENPOINT,
            this.new_message,
            {
              headers:{
                'Allow-Control-Allow-Origin':'*'
              }
            }
          )
          .then(res=>{
            if(res.status ===200 || res.status === 201) {
              this.display_success = true;
            }
          })
          .catch(err=>{
            this.display_err = true;
            console.log(err);
          })
        }
    }
  },
  mounted(){
    $(function() {
      // Back to top button
      $(window).scroll(function () {
        if ($(this).scrollTop() > 1000) {
          $('.back-to-top').fadeIn('slow');
        } else {
          $('.back-to-top').fadeOut('slow');
        }
      });

      // Mobile Navigation
        var $mobile_nav = $('#nav-menu-container').clone().prop({
          id: 'mobile-nav'
        });
        $mobile_nav.find('> ul').attr({
          'class': '',
          'id': ''
        });
        $('body').append($mobile_nav);
        $('body').prepend('<button type="button" id="mobile-nav-toggle"><i class="fa fa-bars"></i></button>');
        $('body').append('<div id="mobile-body-overly"></div>');
        $('#mobile-nav').find('.menu-has-children').prepend('<i class="fa fa-chevron-down"></i>');

        $(document).on('click', '.menu-has-children i', function () {
          $(this).next().toggleClass('menu-item-active');
          $(this).nextAll('ul').eq(0).slideToggle();
          $(this).toggleClass("fa-chevron-up fa-chevron-down");
        });

        $(document).on('click', '#mobile-nav-toggle', function () {
          $('body').toggleClass('mobile-nav-active');
          $('#mobile-nav-toggle i').toggleClass('fa-times fa-bars');
          $('#mobile-body-overly').toggle();
        });

        $(document).click(function (e) {
          var container = $("#mobile-nav, #mobile-nav-toggle");
          if (!container.is(e.target) && container.has(e.target).length === 0) {
            if ($('body').hasClass('mobile-nav-active')) {
              $('body').removeClass('mobile-nav-active');
              $('#mobile-nav-toggle i').toggleClass('fa-times fa-bars');
              $('#mobile-body-overly').fadeOut();
            }
          }
        });

    });
    //load analytics
    this.$gtag.event('home', { method: 'Google' })
  },
  data () {
    return {
      articles:[
      {
        articleTitle:"What Mihandzu Does To Empower Learners",
        author:"By Phodiso Seleka",
        article: "Mihandzu makes the support and opportunities that empower learners in vulnerable communities accessible so that they can improve their academic performance. We do this through the following programs: <br/>Saturday Maths Program ",
        image : require('../assets/images/intro-image.jpg'),
        articleId:1
      },
      {
        articleTitle:"COVID Relief Measures to Battle Education",
        author:"By Khanya Memela",
        article: "If a Grade 9 learner was to be asked this time last year if they would not go to school for almost the entire 2020 academic year, they would call you insane because schools never close. Yet, in unprecedented times, learners ",
        image : require('../assets/images/intro-image.jpg'),
        articleId:2
      },
      {
        articleTitle:"Migrant Children and Their Right to Education",
        author:"By Reneilwe",
        article: "#ZimbabweanLivesMatter has highlighted the plight of Zimbabweans in a setting where their democracy does not benefit them as it should. While we’re quick to share hashtags and pictures online, where do we prove our stance in actions?",
        image : require('../assets/images/intro-image.jpg'),
        articleId:3
      },
      {
        articleTitle:"Mandela Day",
        author:"By Reneilwe",
        article: "'There is nothing more important in life than giving. Tolerance is forged when people look beyond their own desires.'-Nelson Mandela. <br/><br/>Nelson Mandela’s journey has inspired a number of people throughout the world. It is only natural that his ideals resonate with those involved in helping uplift their communities through their time and resources. <br/><br/>Through the years, Mihandzu has experienced ‘Good Fruit People’ through volunteers, donors, and stakeholders alike who give back to the community through their time and resources offered towards Mihandzu driven initiatives. The power and significance of this giving is demonstrated through the impact made on our beneficiaries. The time spent imparting knowledge though the mathematics, IT and career guidance programmes is priceless. <br/><br/>Our volunteers, who are young professionals and tertiary students, have found it to be a priority to sow back into our communities. Not only does this prove ‘Good Fruit People’ are exceptional, but that their characters align with that of Nelson Mandela. To be selfless enough to see success not only as a personal goal, but an ideal which must include the communities where we come from. <br/><br/>Mandela month is a reminder of the importance of giving back. This reminder continues to inspire us as Mihandzu that the seeds we sow will bear good fruit. ",
        image : require('../assets/images/intro-image.jpg'),
        articleId:'mandela_day'
      },
      ],
      display_err: false,
      display_success: false,
      new_message: {
        fullname: '',
        email: '',
        subject: '',
        message_text: ''
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

@import '../assets/modules/bootstrap/css/bootstrap.min.css';
@import '../assets/modules/font-awesome/css/font-awesome.min.css';
@import '../assets/modules/ionicons/css/ionicons.min.css';


@import '../style/style.css';
</style>
