<template>
    <div class='articles'>
        <div class="container">
            <div class="col-md-12" v-for="(article, index) in articles" :key="index">
                <div v-if="artId == article.articleId">
                    <h1>{{article.articleTitle}}</h1>
                    <h3>By {{article.author}}</h3>
                    <div v-html="article.article"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default{
        name: 'articles',
        mounted() {
            this.artId = this.$route.params.artId
            console.log('Articles: ', this.artId)
            console.log('PARAMS: ', this.$route.params)
        },
        data(){
            return{
                artId: null,
                title:"articles",
                articles:[
                {
                    articleTitle:"What Mihandzu Does To Empower Learners",
                    author:"Phodiso Seleka",
                    article: `<p> Mihandzu makes the support and opportunities that empower learners in vulnerable communities accessible so that they can improve their academic performance. We do this through the following programs:</p><h4>Saturday Maths Program</h4>This program aims to create a strong foundation in maths, closing knowledge gaps, helping learners feel confident enough to independently tackle challenging work. Our tutors, who work as volunteers, set themselves apart by guiding the learners through the work that is set at the right level for them, keeping them engaged to make progress. Our learners are supported and encouraged to work out answers by themselves, helping them to become self-sufficient and successful for the future.</p>
                              <h4>IT Program</h4><p>The main objective of this program is to provide good quality informal computer education to our learners. Our focus is on the effective use of computers and the internet. Computer coding has also been added to help them solve problems in logical and creative ways.</p>
                              <h4>Career Guidance Program</h4><p>The purpose of this career program is to help learners gain knowledge about different opportunities so that they can be better prepared to make informed choices on their future careers. The program helps them develop self-leadership skills while exposing them to artisan related careers.</p>
                              <p>Like many organisations, these programs have been affected by COVID-19 which we’re trying to assist with. To allow for continuous engagement between our learners and tutors, online WhatApp sessions have been introduced where learners can consult our tutors anytime if they have questions/queries.</p>
                              <p>Through our efforts, we hope to continue closing the education gap.</p>`,
                    image : require('../assets/images/intro-image.jpg'),
                    articleId:1
                },
                {
                    articleTitle:"COVID Relief Measures to Battle Education",
                    author:"Khanya Memela",
                    article: `<p>If a Grade 9 learner was to be asked this time last year if they would not go to school for almost the entire 2020 academic year, they would call you insane because schools never close. Yet, in unprecedented times, learners have been home for almost five months; it’s why Mihandzu has set up relief measures to assist with learning during this global pandemic. <br/>
                            <br/>While learners are living through an unimaginable reality, these are the ways that we’re helping with COVID-19 relief:</p>
                            <ul>
                            <li>We have provided online daily tutorials to over 120 learners to date.</li>
                            <li>We have provided food parcels to over 100 learners and their families </li>
                            <li>We have provided over 300 gigabytes of data to our learners </li>
                            <li>We have delivered over 100 physical learning materials to learners with limited access to the internet.</li>
                            </ul>
                            <p>By providing relief to learners through the provision of online learning or delivery of food parcels, we’re ensuring that learners remain mentally active while not being exposed to food insecurity. </p>
                            <p>As our efforts continue as an organisation, we remain hopeful that learners will find themselves in classrooms again, back to a state of familiarity and normality. </p>`,
                    image : require('../assets/images/intro-image.jpg'),
                    articleId:2
                },
                {
                    articleTitle:"Migrant Children and Their Right to Education",
                    author:"Reneilwe",
                    article: `<p>#ZimbabweanLivesMatter has highlighted the plight of Zimbabweans in a setting where their democracy does not benefit them as it should. While we’re quick to share hashtags and pictures online, where do we prove our stance in actions? </p>
                            <p>South Africa has experienced its largest in-flow of migrants since the start of the crisis in Zimbabwe almost two decades ago. However, xenophobia and the undertones of the aftermath perhaps make life for Zimbabwean migrants as difficult here as it is in Zimbabwe; particularly when they must enrol into the South African education system. </p>
                            <p>Legislative and practical confusion makes enrolment to South African schools difficult for the children of refugees, asylum seekers and migrants. From demands by school administrators and principals for study permits and birth certificates, language admission tests, claims that schools are ‘full’, being relegated to the bottom of enrolment lists, financial hardship, geographical inaccessibility to unwarranted fee demands. </p>
                            <p>What this does is create a barrier towards education, which is the cornerstone for any society to function efficiently. While the SA educational system may have its own shortfalls, it is still vital to breaking the cycle of poverty. Much more so to those who have left their home country in search for a better life. </p>
                            <p>What #ZimbabweanLivesMatter has highlighted the difference between sharing a hashtag in solidarity versus the actions behind it in everyday life. While we might want to help in a manner that impacts Zimbabwe directly, being more courteous in action would be more significant. Allowing migrant children to enrol in schools would enable them to gain skills that empower not only the South African labour force but the Zimbabwean one as well. After all, our development is interdependent to our upliftment. </p>`,
                    image : require('../assets/images/intro-image.jpg'),
                    articleId:3
                },
                {
                    articleTitle:"Mandela Day",
                    author:"Reneilwe",
                    article: "'There is nothing more important in life than giving. Tolerance is forged when people look beyond their own desires.'-Nelson Mandela. <br/><br/>Nelson Mandela’s journey has inspired a number of people throughout the world. It is only natural that his ideals resonate with those involved in helping uplift their communities through their time and resources. <br/><br/>Through the years, Mihandzu has experienced ‘Good Fruit People’ through volunteers, donors, and stakeholders alike who give back to the community through their time and resources offered towards Mihandzu driven initiatives. The power and significance of this giving is demonstrated through the impact made on our beneficiaries. The time spent imparting knowledge though the mathematics, IT and career guidance programmes is priceless. <br/><br/>Our volunteers, who are young professionals and tertiary students, have found it to be a priority to sow back into our communities. Not only does this prove ‘Good Fruit People’ are exceptional, but that their characters align with that of Nelson Mandela. To be selfless enough to see success not only as a personal goal, but an ideal which must include the communities where we come from. <br/><br/>Mandela month is a reminder of the importance of giving back. This reminder continues to inspire us as Mihandzu that the seeds we sow will bear good fruit. ",
                    image : require('../assets/images/intro-image.jpg'),
                    articleId:'mandela_day'
                }]
            }
        }
    }
</script>

<style scoped>

</style>
