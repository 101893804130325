import Vue from 'vue'
import router from './router'
import App from './App.vue'

import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.min.js'

import {$} from 'jquery'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import VueCookies from 'vue-cookies'
import VueGtag from 'vue-gtag'

import * as Sentry from "@sentry/vue"

const third_party = require('./utils/config')

global.$ = $

Vue.use(VueGtag, {
  config: {id: 'G-5XJSLD38X8' || third_party.GOOGLE_ANALYTICS},
  appName: 'mihandzu_v_app', 
  pageTrackerScreenviewEnabled: true,
}, router)

Vue.use(VueCookies)
Vue.config.productionTip = true
Vue.prototype.$axios = axios

Sentry.init({
  Vue,
  dsn: "https://e7873e3f7e5641cd9b2c9ab1ee318bb9@o1074718.ingest.sentry.io/6074536",
});

new Vue({
  router,
  vuetify,
  render: h=> h(App)
}).$mount("#app")