<template>
    <v-container>
    <v-card class='join'>
        <div class="container">
            <div class="section-header">
                <h2>Become a Tutor</h2>
                <p v-show="!display_success">
                    Join Mihandzu's team of tutors that aims to change the lives of children born into poverty, abusive enviroments and other social upheveals         
                </p><br/>
            </div>
            <form v-show="!display_success" class="form_temp">
                <v-row>
                    <v-col>
                        <v-text-field
                        :rules="[v => !!v || 'Your full name is required']"
                        v-model="new_volunteer.fullname"
                        label="Fullname"
                        required
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field
                        v-model="new_volunteer.email"
                        label="Email"
                        required
                        :rules="[v => !!v || 'Email address is required']"
                        ></v-text-field>
                    </v-col>
                    <v-col>
                        <v-text-field
                        v-model="new_volunteer.cellphone"
                        label="Cellphone"
                        required
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col
                        cols="4"
                        md="2">
                        <v-text-field
                        v-model="new_volunteer.age"
                        label="Age"
                        required
                        :rules="[v => !!v || 'Your age is required']"
                        ></v-text-field>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-radio-group v-model="new_volunteer.transport">
                            <template v-slot:label>
                                <div>Do you have your own transportation?</div>
                            </template>
                            <v-radio value=true>
                                <template v-slot:label>
                                <div>Yes, I do</div>
                                </template>
                            </v-radio>
                            <v-radio value=false>
                                <template v-slot:label>
                                <div>No, I need a lift</div>
                                </template>
                            </v-radio>
                            </v-radio-group>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-radio-group v-model="new_volunteer.tutoring_experience">
                            <template v-slot:label>
                                <div>Do you have any tutoring experience?</div>
                            </template>
                            <v-radio value=true>
                                <template v-slot:label>
                                <div>Yes</div>
                                </template>
                            </v-radio>
                            <v-radio value=false>
                                <template v-slot:label>
                                <div>No</div>
                                </template>
                            </v-radio>
                            </v-radio-group>
                    </v-col>
                </v-row>

            <v-expand-transition>
                <v-row>
                    <v-col>
                        <template  v-if="this.new_volunteer.tutoring_experience == 'false' || this.new_volunteer.tutoring_experience == 'false'">
                            <v-radio-group v-model="new_volunteer.comfortable_talking">
                            <template v-slot:label>
                                <div>Are you comfortable talking infront of a full class?</div>
                            </template>
                            <v-radio value=true>
                                <template v-slot:label>
                                <div>Yes</div>
                                </template>
                            </v-radio>
                            <v-radio value=false>
                                <template v-slot:label>
                                <div>No</div>
                                </template>
                            </v-radio>
                            </v-radio-group>
                        </template>
                    </v-col>
                </v-row>
            </v-expand-transition>
            <v-expand-transition>
                <v-row v-if="this.new_volunteer.tutoring_experience === 'true'  || this.new_volunteer.tutoring_experience == 'true'">
                    <v-col>
                        <p>Which levels have you tutored?</p>
                        <v-checkbox
                        v-model="new_volunteer['highest_level_tutored']"
                        label="Primary School"
                        color="red"
                        value="primary_school"
                        hide-details
                        ></v-checkbox>
                        <v-checkbox
                        v-model="new_volunteer['highest_level_tutored']"
                        label="High School"
                        color="red darken-3"
                        value="high_school"
                        hide-details
                        ></v-checkbox>
                        <v-checkbox
                        v-model="new_volunteer['highest_level_tutored']"
                        label="University"
                        color="red darken-3"
                        value="university"
                        hide-details
                        ></v-checkbox>
                    </v-col>
                </v-row>
            </v-expand-transition>
                <v-row>
                    <v-col>
                        <v-checkbox 
                            v-model="new_volunteer.communication_consent"
                            :rules="[v => !!v || 'Please agree to Mihandzu contacting you']">
                            <template v-slot:label>
                                <div>
                                I agree to <strong>Mihandzu Learn</strong> using the provided information to contact me
                                </div>
                            </template>
                        </v-checkbox>
                    </v-col>
                </v-row>
                    <div id="msgError" v-show="display_err" class="alert alert-danger alert-dismissible">
                        <strong>Error:</strong> <span id="txtErr">Something went wrong. Please try again later.</span>
                    </div>
                <v-row class="form-group middle">
                    <v-col>
                        <v-btn class="submit_btn" @click="join()">Join</v-btn>
                    </v-col>
                </v-row>
            </form>

            <div v-show="display_success" class="card text-white bg-success mb-3" style="max-width: 25rem;">
                <div class="card-header">Thank You</div>
                <div class="card-body">
                    <h5 class="card-title">You have taken the first step</h5>
                    <hr/>
                    <p class="card-text">We will be contacting you soon to take the next step in the process of joining our amazing team.</p>
                    <br/>
                    <router-link to='/'><a href="#" class="btn btn-light">Go to Home Page</a></router-link>
                </div>
            </div>
        </div>
    </v-card>
    </v-container>
</template>

<script>
    import axios from 'axios'
    import network_config from '../utils/config'

    export default{
        name: 'join',
        data: ()=> {
            return {
                display_err: false,
                display_success: false,
                new_volunteer: {
                    fullname: '',
                    email: '',
                    cellphone: '',
                    age: '',
                    transport: null,
                    tutoring_experience: null,
                    highest_level_tutored: [],
                    comfortable_talking: null,
                    communication_consent: null,
                }
            }
        },
        methods: {
            join() {
                if(this.new_volunteer.fullname || this.new_volunteer.email || this.new_volunteer.cellphone) {
                    console.log("HERE")
                    axios.post(
                        network_config.network.VOLUNTEER_ENPOINT,
                        this.new_volunteer,
                        {
                            headers:{
                                'Allow-Control-Allow-Origin':'*'
                            }
                        }
                    )
                    .then(res=>{
                        if(res.status ===200 || res.status === 201) {
                            this.display_success = true
                        }
                    })
                    .catch(err=>{
                        this.display_err = true;
                        console.log(err)
                    })
                }
            }
        }
    }

</script>

<style scoped>
    .submit_btn {
        color: #13A6D7;
        background-color: #fff;
    }
</style>
